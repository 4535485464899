<template>
  <div>
    <v-card-title class="justify-center title-card">
      <h2>Valide o seu token</h2>
    </v-card-title>

    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
      <v-row class="flex-column mt-5" align="start">
        <v-col class="d-flex flex-column align-center py-0">
          <span class="text-filelds"
            >Por favor, insira o token recebido no seu e-mail</span
          >
        </v-col>
        <v-col>
          <v-text-field
            v-model="token"
            placeholder=""
            :rules="[(v) => !!v || 'Token requerido']"
            color="secondary"
            required
            outlined
            rounded
            class="input"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-btn
      :disabled="!valid"
      large
      rounded
      color="success"
      @click="validaToken"
      :loading="loading"
      class="my-4 btn-login py-3"
    >
      <p style="color: white; margin-bottom: 0px">Validar token</p>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    falha: { type: Boolean },
  },
  data() {
    return {
      valid: false,
      token: "",
      loading: false,
    };
  },
  methods: {
    validaToken() {
      this.loading = true;
      let token = { code: this.token };
      this.$emit("validaToken", token);
    },
  },
  watch: {
    falha(newValue, oldValue) {
      if (newValue == true) {
        {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}
.senha {
  margin-top: 0 !important;
}
</style>
