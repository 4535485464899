var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("Alert", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showError,
            expression: "showError",
          },
        ],
        attrs: { type: "error", message: _vm.error },
      }),
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { id: "auth" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-5",
              attrs: { elevation: "10", color: "#ffffff" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-slide-y-transition",
                    {
                      attrs: { appear: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "heading",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "display-2 font-weight-bold mb-2",
                                  },
                                  [_vm._v("Forget Password")]
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("v-card-text", { staticClass: "text-center card" }, [
                        _c("figure", { staticClass: "btn-back" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/icons/arrow_back_ios.svg"),
                              alt: "Botão voltar",
                            },
                            on: { click: _vm.goBack },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "wrapper-stepper" },
                          [
                            _c("div", { staticClass: "stepper" }),
                            _vm._l(4, function (item) {
                              return _c(
                                "div",
                                { key: item, staticClass: "stepper-content" },
                                [
                                  _vm.step == item
                                    ? _c(
                                        "div",
                                        [
                                          _c(_vm.passos[item - 1], {
                                            tag: "component",
                                            attrs: {
                                              showMessage: _vm.showMessage,
                                              falha: _vm.falha,
                                            },
                                            on: {
                                              forgotEmail: _vm.sendEmail,
                                              validaToken: _vm.validaToken,
                                              newPassword: _vm.newPassword,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm.step >= 2
                          ? _c("div", { staticClass: "controls" }, [
                              !_vm.showMessage.sucesso
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-voltar",
                                      attrs: { disabled: _vm.step == 1 },
                                      on: {
                                        click: function ($event) {
                                          _vm.step--
                                        },
                                      },
                                    },
                                    [_vm._v(" Voltar ")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }