var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-slide-y-transition",
        {
          attrs: { appear: "" },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center" }, [
                    _c(
                      "h1",
                      { staticClass: "display-2 font-weight-bold mb-2" },
                      [_vm._v("Login")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-card-text",
            { staticClass: "text-center card" },
            [
              _c("v-card-title", { staticClass: "justify-center title-card" }, [
                _c("h2", [_vm._v("Resetar senha")]),
              ]),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-column senha",
                      attrs: { align: "start" },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex flex-column align-start py-0" },
                        [
                          _c("span", { staticClass: "text-filelds" }, [
                            _vm._v("Senha"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "********",
                              rules: _vm.passwordRules,
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.show ? "text" : "password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show = !_vm.show
                              },
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-column senha",
                      attrs: { align: "start" },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex flex-column align-start py-0" },
                        [
                          _c("span", { staticClass: "text-filelds" }, [
                            _vm._v("Confirmação senha"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "********",
                              rules: _vm.confirmRules,
                              color: "secondary",
                              required: "",
                              outlined: "",
                              rounded: "",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.show2 ? "text" : "password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show2 = !_vm.show2
                              },
                            },
                            model: {
                              value: _vm.passwordConfirmed,
                              callback: function ($$v) {
                                _vm.passwordConfirmed = $$v
                              },
                              expression: "passwordConfirmed",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.showMessage.sucesso
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-4 btn-login py-3",
                      attrs: {
                        disabled: !_vm.valid,
                        large: "",
                        rounded: "",
                        color: "success",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.resetSenha },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            color: "white",
                            "margin-bottom": "0px",
                          },
                        },
                        [_vm._v("Resetar")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }