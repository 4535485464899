<template>
  <v-app>
    <Alert type="error" :message="error" v-show="showError" />

    <v-container id="auth" class="fill-height">
      <v-card class="pa-5" elevation="10" color="#ffffff">
        <v-row justify="center">
          <v-slide-y-transition appear>
            <template v-slot:heading>
              <div class="text-center">
                <h1 class="display-2 font-weight-bold mb-2">Forget Password</h1>
              </div>
            </template>

            <v-card-text class="text-center card">
              <figure class="btn-back">
                <img
                  src="@/assets/icons/arrow_back_ios.svg"
                  alt="Botão voltar"
                  @click="goBack"
                />
              </figure>

              <div class="wrapper-stepper">
                <div class="stepper"></div>

                <div class="stepper-content" v-for="item in 4" :key="item">
                  <div v-if="step == item">
                    <component
                      :is="passos[item - 1]"
                      @forgotEmail="sendEmail"
                      @validaToken="validaToken"
                      @newPassword="newPassword"
                      :showMessage="showMessage"
                      v-bind:falha="falha"
                    ></component>
                  </div>
                </div>
              </div>

              <div class="controls" v-if="step >= 2">
                <button
                  v-if="!showMessage.sucesso"
                  class="btn-voltar"
                  @click="step--"
                  :disabled="step == 1"
                >
                  Voltar
                </button>
              </div>
            </v-card-text>
          </v-slide-y-transition>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<style scoped>
#auth {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--principal-azul);
  max-width: unset;
}
/* STEPPER */
.wrapper-stepper {
  /*  */
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.09);
}
.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 0;
  margin-bottom: 24px;
}
.stepper-progress {
  position: absolute;
  background-color: #c5c5c5;
  height: 2px;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.stepper-progress-bar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: var(--principal-azul);
  transition: all 500ms ease;
}
.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c5c5c5;
  transition: all 500ms ease;
}
.stepper-item-counter {
  height: 6px;
  width: 6px;
  display: grid;
  place-items: center;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #c5c5c5;
  position: relative;
}
.stepper-item-counter .icon-success {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  width: 24px;
  transition: all 500ms ease;
}
.stepper-item-counter .number {
  font-size: 22px;
  transition: all 500ms ease;
}
.stepper-item-title {
  position: absolute;
  font-size: 14px;
  bottom: -24px;
}

.stepper-item.success .stepper-item-counter {
  border-color: var(--principal-azul);
  background-color: #c8ebc1;
  color: #fff;
  font-weight: 600;
}
.stepper-item.success .stepper-item-counter .icon-success {
  opacity: 1;
  transform: scale(1);
}
.stepper-item.success .stepper-item-counter .number {
  opacity: 0;
  transform: scale(0);
}
.stepper-item.success .stepper-item-title {
  color: var(--principal-azul);
}

.stepper-item.current .stepper-item-counter {
  border-color: var(--principal-azul);
  background-color: var(--principal-azul);
  color: #fff;
  font-weight: 600;
}
.stepper-item.current .stepper-item-title {
  color: #818181;
}

.stepper-pane {
  color: #333;
  text-align: center;
  padding: 120px 60px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.09);
  margin: 40px 0;
}

.controls {
  display: flex;
  margin-top: 1rem;
}

.btn-voltar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 25px;
  border: 1px solid;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  transition: all 150ms;
  border-radius: 4px;
  width: fit-content;
  font-size: 0.75rem;
  color: #333;
  border: 2px solid #c4c4c4;
  border-radius: 20px;
  color: #c4c4c4;
  transition: 0.2s ease-in-out;
}
.btn-voltar:hover {
  background: #c4c4c4;
  color: white;
}
.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.btn--green-1 {
  background-color: var(--principal-azul);
  border-color: var(--principal-azul);
  color: #fff;
  margin-left: auto;
}

/* FIM DO STEPPER */

.text-filelds {
  font-size: 1.2em;
}
.card {
  padding: 1.5rem 1.5rem 1.5rem !important;
  width: 400px;
}
.btn-back {
  display: flex;
  cursor: pointer;
}
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}
.senha {
  margin-top: 0 !important;
}
</style>

<script>
import ApiService from "../../../services/ApiService";
import Alert from "../../../components/Alert.vue";
import stepOne from "../Auth/stepper/stepOne.vue";
import stepTwo from "../Auth/stepper/stepTwo.vue";
import stepThree from "../Auth/stepper/stepThree.vue";

export default {
  name: "ForgotPassword",

  data: () => ({
    step: 1,
    showMessage: {},
    loading: false,
    error: "",
    valid: false,
    pin: "",
    confirmPassword: "",
    userId: "",
    email: null,
    phoneNumber: null,
    password: "",
    passwordRules: [(v) => !!v || "Senha requerida"],
    apiService: new ApiService(),
    showError: false,
    falha: undefined,
  }),

  components: {
    Alert,
    stepOne,
    stepTwo,
    stepThree,
  },

  methods: {
    async sendEmail(value) {
      this.loading = true;
      try {
        let req = await this.apiService.post("user/forgot-password", value);
        let res = req.content.statusCode;
        res == 200 ? (this.falha = false) : (this.falha = true);
        sessionStorage.setItem("userId", req.content.userId);


        this.$toast.success('Token enviado para seu e-mail')
        this.step++;
        this.loading = false;

      } catch (error) {
          setTimeout(() =>{
            this.falha = true;
        }, 5000)

        this.falha = false;
        this.loading = false;
        this.$toast.error("Erro ao enviar e-mail de verificação");
      }
    },

    async validaToken(value) {
      try {
          let req = await this.apiService.post("user/forgot-password/validate",value);
          let success = req.statusCode;
          success == 200 ? this.step++ : "";
          sessionStorage.setItem("token", value.code);
      } catch (error) {
          setTimeout(() =>{ this.falha = true }, 5000)
          this.falha = false;
          this.$toast.error("Erro ao validar token");
      }
    },

    async newPassword(value) {
      this.loading = true;

      try {
        let req = await this.apiService.post("user/forgot-password/reset",value);

        if (req.statusCode === 200) {
            this.showMessage = { sucesso: true };

            this.$toast.success("Senha atualizada com sucesso");

            setTimeout(() => {
              this.$router.push("/auth");
            }, 5000);
        }

      } catch (error) {
        this.$toast.error("Erro ao criar nova senha");
        setTimeout(() =>{ this.falha = true }, 5000);
        this.falha = false;
      }
    },

    validate() {
      this.loading = true;

      if (this.email == null && this.phoneNumber == null) {
        this.error = "Insira um email ou telefone para recuperação!";
        this.showError = true;

        setTimeout(() => {
          this.showError = false;
        }, 4000);

        this.loading = false;

        return;
      }

      let phone;

      if (this.phoneNumber != null) {
        phone = this.phoneNumber.replace("(", "");
        phone = phone.replace(")", "");
        phone = phone.replace("-", "");
        phone = phone.replace(" ", "");
      }
      const user = {
        Email: this.email,
        PhoneNumber: phone,
      };

      this.apiService.post("user/forgot-password", user).then((response) => {
        this.userId = response.userId;
        this.loading = false;
        this.step++;
      });
    },
    toLogin() {
      this.$router.push("/");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    stepperProgress() {
      return (100 / 3) * (this.step - 1) + "%";
    },
    passos() {
      return ["stepOne", "stepTwo", "stepThree"];
    },
  },
};
</script>