<template>
  <v-row justify="center">
    <v-slide-y-transition appear>
      <template v-slot:heading>
        <div class="text-center">
          <h1 class="display-2 font-weight-bold mb-2">Login</h1>
        </div>
      </template>

      <v-card-text class="text-center card">
        <v-card-title class="justify-center title-card">
          <h2>Resetar senha</h2>
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
          <v-row class="flex-column senha" align="start">
            <v-col class="d-flex flex-column align-start py-0">
              <span class="text-filelds">Senha</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password"
                placeholder="********"
                :rules="passwordRules"
                color="secondary"
                required
                outlined
                rounded
                class="input"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              />
            </v-col>
          </v-row>

          <v-row class="flex-column senha" align="start">
            <v-col class="d-flex flex-column align-start py-0">
              <span class="text-filelds">Confirmação senha</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="passwordConfirmed"
                placeholder="********"
                :rules="confirmRules"
                color="secondary"
                required
                outlined
                rounded
                class="input"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
              />
            </v-col>
          </v-row>
        </v-form>
        <!-- <div class="mensagem-sucesso" v-if="showMessage.sucesso">
          <span>Senha atualizada com sucesso</span>
        </div> -->

        <v-btn
          v-if="!showMessage.sucesso"
          :disabled="!valid"
          large
          rounded
          color="success"
          @click="resetSenha"
          :loading="loading"
          class="my-4 btn-login py-3"
        >
          <p style="color: white; margin-bottom: 0px">Resetar</p>
        </v-btn>
      </v-card-text>
    </v-slide-y-transition>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      show2: false,
      loading: false,
      error: false,
      valid: true,
      documentNumber: "",
      documentNumberRules: [(v) => !!v || "CPF requerido"],
      password: "",
      passwordConfirmed: "",
      passwordRules: [(v) => !!v || "Senha requerida"],
      confirmRules: [(v) => {
        if (!v) { return "Confirmação requerida" }
        if (v !== this.password) { return "Senha e confirmação são diferentes" }
        return true;
      }],
      username: undefined,
    };
  },
  props: {
    showMessage: { type: Object },
    falha: { type: Boolean },
  },
  methods: {
    resetSenha() {
      this.loading = true;

      let code = sessionStorage.getItem("token");
      let userId = sessionStorage.getItem("userId");

      let newPassword = {
        code: code,
        userId: userId,
        newPassword: this.password,
      };

      if (this.password == this.passwordConfirmed) {
        this.$emit("newPassword", newPassword);
      }
    },
  },
  watch: {
    showMessage(newValue, oldValue) {
      if (newValue.sucesso) {
        this.password = "";
        this.passwordConfirmed = "";
      }
    },
    falha(newValue, oldValue) {
      if (newValue == true) {
        {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.mensagem-sucesso {
  color: rgb(48, 184, 48);
}
.text-filelds {
  font-size: 1.2em;
}
.card {
  padding: 1.5rem 1.5rem 1.5rem !important;
  width: 400px;
}
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}
.senha {
  margin-top: 0 !important;
}
</style>
