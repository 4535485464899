<template>
  <div>
    <v-card-title class="justify-center title-card">
      <h2>Esqueceu a senha?</h2>
    </v-card-title>

    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
      <v-row class="flex-column mt-5" align="start">
        <v-col class="d-flex flex-column align-center py-0">
          <span class="text-filelds">Por favor, informe seu email</span>
        </v-col>
        <v-col>
          <v-text-field
            v-model="email"
            placeholder="curtis.weaver@example.com"
            :rules="[(v) => !!v || 'E-mail requerido']"
            color="secondary"
            required
            outlined
            rounded
            class="input"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-btn
      :disabled="!valid"
      large
      rounded
      color="success"
      @click="sendEmail"
      :loading="loading"
      class="my-4 btn-login py-3"
    >
      <p style="color: white; margin-bottom: 0px">Enviar email</p>
    </v-btn>
  </div>
</template>

<script>
export default {
    props:{
        falha:{type: Boolean}
    },
  data() {
    return {
      valid: false,
      email: "",
      loading : false
    };
  },
  methods: {
    sendEmail() {
    this.loading = true;
      let mockUser = {
        UserName: "",
        Email: this.email,
      };

      this.$emit('forgotEmail', mockUser)
    },
   
  },
   watch:{
        falha(newValue, oldValue){
          if(newValue == true){{
            this.loading = false
          }}
        }
    }
};
</script>

<style  scoped>
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}
.senha {
  margin-top: 0 !important;
}
</style>