var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-card-title", { staticClass: "justify-center title-card" }, [
        _c("h2", [_vm._v("Valide o seu token")]),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "flex-column mt-5", attrs: { align: "start" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex flex-column align-center py-0" },
                [
                  _c("span", { staticClass: "text-filelds" }, [
                    _vm._v("Por favor, insira o token recebido no seu e-mail"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "",
                      rules: [(v) => !!v || "Token requerido"],
                      color: "secondary",
                      required: "",
                      outlined: "",
                      rounded: "",
                    },
                    model: {
                      value: _vm.token,
                      callback: function ($$v) {
                        _vm.token = $$v
                      },
                      expression: "token",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "my-4 btn-login py-3",
          attrs: {
            disabled: !_vm.valid,
            large: "",
            rounded: "",
            color: "success",
            loading: _vm.loading,
          },
          on: { click: _vm.validaToken },
        },
        [
          _c("p", { staticStyle: { color: "white", "margin-bottom": "0px" } }, [
            _vm._v("Validar token"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }